import req from "../methods/req"
import method from '../methods/method'

// 获取客户列表
export const getCateList = function (data){
    return method.get_list({
        url:'/manage/crm.notice/typeList',
        ...data
    })
}

export const cateList = function (data){
	return req({
		url:'/manage/crm.notice/typeSearch',
		...data,
	})
}

export const changeCate = function (data){
    return req({
        url:'/manage/crm.notice/changeType',
        ...data
    })
}


export const getCate = function (data){
    return req({
        url:'/manage/crm.notice/getType',
        ...data
    })
}

export const delCate = function (data){
    return req({
        url:'/manage/crm.notice/deleteType',
        ...data
    })
}


export const getHelpList = function (data){
    return method.get_list({
        url:'/manage/crm.notice/noticeList',
        ...data
    })
}

export const changeHelp = function (data){
    return req({
        url:'/manage/crm.notice/changeNotice',
        ...data
    })
}


export const getHelp = function (data){
    return req({
        url:'/manage/crm.notice/getNotice',
        ...data
    })
}

export const delHelp = function (data){
    return req({
        url:'/manage/crm.notice/deleteNotice',
        ...data
    })
}

export const readUser = function (data){
    return method.get_list({
        url:'/manage/crm.notice/readUser',
        ...data
    })
}


export const readSeller = function (data){
    return method.get_list({
        url:'/manage/crm.notice/readSeller',
        ...data
    })
}

