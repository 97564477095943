<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :slot_table_list="['operation','status']"
			:form_data_seo="form_data_seo" ref="list">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加公告</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="look(data.record)">已读员工</a>
				<a-divider type="vertical" />
				<a v-auth="'change'" @click="lookDis(data.record)">已读经销商</a>
				<a-divider type="vertical" />
				<a v-auth="'change'" @click="edit(data.record)">编辑</a>
				<a-divider type="vertical" />
				<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
			</template>
		</TableList>

		<EditPop @handleCancel='handleCancel' ref="edit_pop" :width="1000" @handleSubmit="submit" :form_data="form_data"
			:visible.sync="visible">
			<div slot='dap_ids'>
				<a-radio-group :disabled='temp_id?true:false' name="radioGroup" v-model:value="is_all">
					<a-radio :value="3">不通知</a-radio>
					<a-radio :value="1">全部</a-radio>
					<a-radio :value="2">部分</a-radio>
				</a-radio-group>
				<div v-if="is_all=='2' ">
					<Form ref="form_dap_data" :form_data="form_dap_data">
					</Form>
				</div>
			</div>
			<div slot='distributor_ids'>
				<a-radio-group :disabled='temp_id?true:false' name="radioGroup" v-model:value="is_all_seller">
					<a-radio :value="3">不通知</a-radio>
					<a-radio :value="1">全部</a-radio>
					<a-radio :value="2">部分</a-radio>
				</a-radio-group>
				<div v-if="is_all_seller=='2' ">
					<div class='seller_ids_box' v-if=" seller_ids.length>0">

						<a-tag :closable="temp_id?false:true" @click='delTag(index)' v-for="(item,index) in seller_ids"
							:key='index'>{{item.seller_name}}</a-tag>
					</div>
					<a-button v-if='!temp_id' @click="addSeller" type="primary">选择经销商</a-button>

				</div>
			</div>

			<div slot='files'>
				<a-upload :showUploadList="false" @change="handleChangeImg($event)" :customRequest="upload"
					@preview="handlePreview">
					<a-button>
						<a-icon type="upload" /> 上传
					</a-button>
				</a-upload>
				<div class="file_box">
					<div class="file_li" v-for="(file, file_index) in files_list">
						<!-- <img  v-else src="@/assets/pdf.png" alt=""> -->
						<span @click='seeFileFun(file)'>{{ file.name }} | {{ file.type }} | {{ file.size }}</span>

						<a-icon style='color:red;' @click="files_list.splice(index,1)" type="close-circle" />
					</div>
				</div>
			</div>
		</EditPop>
		<PopTableList rowKey="id" ref="pop_table_list" :is_created_get_list="false" :columns="columns_pop"
			:get_table_list="get_table_list_pop" :visible.sync="visible_pop" :slot_table_list="['address']"
			@submit="visible_pop = false">
			<!-- :form_data_seo="form_data_seo_pop" -->
			<!-- @submit="pop_table_submit" -->
			<template slot="address"
				slot-scope="data">{{data.record.province + data.record.city + data.record.district + data.record.address}}</template>
		</PopTableList>
		<PopTableList rowKey="id" ref="pop_table_list_dis" :is_created_get_list="false" :columns="columns_pop_dis"
			:get_table_list="get_table_list_pop_dis" :visible.sync="visible_pop_dis" :slot_table_list="['address']"
			@submit="visible_pop_dis = false">
			<!-- :form_data_seo="form_data_seo_pop" -->
			<!-- @submit="pop_table_submit" -->
			<template slot="address"
				slot-scope="data">{{data.record.province + data.record.city + data.record.district + data.record.address}}</template>
		</PopTableList>
		<SelectSellerList @submit="submit_seller" ref='SelectSellerList' :visible.sync="visible_seller">
		</SelectSellerList>
		<a-modal width="80%" v-model:visible="visibleTips" title="预览" @ok="visibleTips=false">
			<iframe v-if="file_type!='png'&& file_type!='jpg'&& file_type!='jpeg'" :src="docUrl" width="100%"
				height="600px"></iframe>
			<img v-else :src="docUrl" width="100%" height="600px"></img>
		</a-modal>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Form from "@/components/Form";
	import EditPop from "@/components/EditPop";
	import SelectSellerList from "@/components/SelectSellerList";
	import TableList from "@/components/TableList";
	import {
		message
	} from "ant-design-vue";
	import {
		getHelpList,
		changeHelp,
		getHelp,
		delHelp,
		cateList,
		readUser,
		readSeller
	} from "@/api/notice";
	import PopTableList from "@/components/PopTableList";
	const columns = [{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "公告分类",
			dataIndex: "type",
		},
		{
			title: "公告标题",
			dataIndex: "name",
		},
		{
			title: "人员",
			dataIndex: "username",
		},
		{
			title: "创建时间",
			dataIndex: "create_time",
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	const columns_pop = [{
			title: "姓名",
			dataIndex: "username",
		},
		{
			title: "部门",
			dataIndex: "department",
		},
		{
			title: "岗位",
			dataIndex: "role",
		},
		{
			title: "时间",
			dataIndex: "update_time",
		},
	];

	const columns_pop_dis = [{
			title: "经销商名称",
			dataIndex: "username",
		},
		{
			title: "DRP_ID",
			dataIndex: "seller_id",
		},
		{
			title: "SAP_id",
			dataIndex: "sap_seller_id",
		},
		{
			title: "阅读时间",
			dataIndex: "update_time",
		},
	];


	export default {
		name: "NoticeList",
		components: {
			EditPop,
			TableList,
			PopTableList,
			SelectSellerList,
			Form
		},
		data() {
			return {
				is_all: 3,
				department_ids: [],
				file_type: '',
				visibleTips: false,
				docUrl: '',
				nameplate_image: [],
				files_list: [],
				visible_seller: false,
				is_all_seller: 3,
				visible_pop_dis: false,
				seller_ids: [],
				get_table_list: getHelpList,
				get_table_list_pop: readUser,
				get_table_list_pop_dis: readSeller,
				columns,
				columns_pop_dis,
				visible: false,
				visible_pop: false,
				temp_id: '',
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [{
							type: "select",
							name: "type",
							title: "公告分类",
							placeholder: '',
							options: {

							},
							list: []
						},
						{
							type: "text",
							name: "title",
							title: "公告标题",
							options: {},
						},
					],
				},
				type_list: [],
				columns_pop,
				form_dap_data: {
					list: [{
						type: "tree-select",
						name: "department_ids",
						title: "",
						options: {},
						treeData: [],
						multiple: true
					}, ]
				},
				form_data: {
					title: '添加',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {

							},
						},
						{
							type: 'select',
							name: 'type_id',
							title: '公告分类',
							options: {
								rules: [{
									required: true,
								}]
							},
							list: [],
						},
						{
							type: 'text',
							name: 'name',
							title: '公告标题',
							options: {
								rules: [{
									required: true,
								}]
							},
						},
						{
							type: "slot",
							name: "dap_ids",
							title: "公开部门",
							options: {},
						},
						{
							type: "slot",
							name: "distributor_ids",
							title: "公开经销商",
							options: {},
						},
						{
							type: "slot",
							name: "files",
							title: "公告附件",
							options: {},

						},
						{
							type: 'editor',
							name: 'content',
							title: '内容',
							options: {
								rules: [{
									required: true,
								}]
							},
						},
					]
				}
			};
		},


		async created() {

			this.$method.get_department().then(res => {
				this.form_dap_data.list.forEach(item => {
					if (item.name == "department_ids") {
						item.treeData = res;

					}
				});
			});

			this.get_cate_list();
		},

		methods: {
			addSeller() {
				this.$refs.SelectSellerList.selectedRowKeys = this.seller_ids.map(it => {
					return it.seller_id
				})
				this.$refs.SelectSellerList.selectedRows = this.select_user_list
				this.visible_seller = true
			},
			handleCancel() {
				this.seller_ids = []
				this.files_list = []
				this.temp_id = ''
				this.is_all = 3
				this.is_all_seller = 3
			},
			delTag(index) {
				this.seller_ids.splice(index, 1)
			},
			seeFileFun(file) {
				this.file_type = file.type
				this.docUrl = file.url.replace("?x-oss-process=style/visit", "")
				if (file.type != 'pdf' && file.type != 'png' && file.type != 'jpg' && file.type != 'jpeg') {
					this.docUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(this.docUrl)}`
				}
				this.visibleTips = true
			},
			async handlePreview(file) {
				this.previewImage = file.url || file.response.url;
				this.previewVisible = true;
			},
			handleChangeImg(e) {

				this.nameplate_image = e ? e.fileList : [];
			},
			upload(e, item) {
				return new Promise((resolve, reject) => {
					this.$req({
						mode: 'file',
						url: '/manage/api.plugs/upload',
						data: {
							file: e.file,
						}
					}).then(res => {
						this.files_list.push(res.data.upload_data)
					}).catch(e => {

					})
				})
			},
			submit_seller(e) {
				const map = new Map()
				this.seller_ids = [...this.seller_ids, ...e.data].filter(item => {
					return !map.has(item.seller_id) && map.set(item.seller_id, item.seller_id)
				});

			},
			add() {
				this.form_data.title = "添加"
				this.form_data.list.forEach(item => {
					item.content = "";
				})
				this.form_dap_data.list.forEach(item => {
					if (item.name == 'department_ids') {
						item.disabled = false;
						item.options.initialValue = null;
					} else {
						item.options.initialValue = '';
					}
				})
				this.visible = true;
			},
			look(record) {
				this.visible_pop = true;

				let data = {
					id: record.id,
				}

				this.$nextTick(res => {
					this.$refs.pop_table_list.set_data('fixed_seo_data', data)
					this.$refs.pop_table_list.get_list();
				})
			},
			lookDis(record) {
				this.visible_pop_dis = true;
				let data = {
					id: record.id,
				}
				this.$nextTick(res => {
					this.$refs.pop_table_list_dis.set_data('fixed_seo_data', data)
					this.$refs.pop_table_list_dis.get_list();
				})
			},
			edit(record) {
				this.form_data.title = "编辑"
				getHelp({
					data: {
						id: record.id
					}
				}).then(res => {

					this.temp_id = record.id
					let data = res.data
					this.form_data.list.forEach((item, index) => {

						this.$nextTick(res => {
							if (item.type == 'select') {
								let val = {};
								val[item.name] = data[item.name];
								this.$refs.edit_pop.setFieldsValue(val)
							}
							if (item.type == 'editor') {
								this.form_data.list[index].content = data[item.name]
							}
							if (item.name == 'name') {
								console.log('aaa', data['name'])
								let val = {};
								val['name'] = data['name'];
								this.$refs.edit_pop.setFieldsValue(val)
							}
						})

					})
					this.form_dap_data.list.forEach(item => {
						item.options.initialValue = data[item.name];
						if (item.name == 'department_ids') {
							item.disabled = true;
						}

					})

					this.is_all_seller = data.is_all_seller
					this.is_all = data.is_all
					this.files_list = data.files ? data.files : []
					if (this.is_all_seller == 2) {
						this.seller_ids = data.seller_info.map(it => {
							return {
								seller_name: it.seller_name,
								sales_seller_id: it.id
							}
						})
					}
					this.visible = true;

				})
			},
			del(record) {
				delHelp({
					data: {
						id: record.id
					},
					info: true,
				}).then(res => {

					this.$refs.list.get_list();
				})
			},
			async get_cate_list() {
				cateList({

				}).then(res => {

					let list = res.data.list.map(val => {
						return {
							key: val.id,
							value: val.name
						}
					});

					this.form_data_seo.list.forEach(item => {
						if (item.name == 'type') {
							item.list = list;
						}
					})

					this.form_data.list.forEach(item => {
						if (item.name == 'type_id') {
							item.list = list;
						}
					})
				})
			},
			submit(e) {
				let data = e.values;

				if (this.is_all_seller == 2 && this.seller_ids.length == 0) {
					message.error("请选择需要经销商")
					return false
				}
				if (this.files_list.length > 0) {
					data.files = this.files_list
				}
				if (this.is_all == 3 && this.is_all_seller == 3) {
					message.error("请选择公告推送范围")
					return false
				}

				if (this.is_all == 2) {

					if (data.department_ids) {
						if (!data.id) {
							data.id = '';
							if (data.department_ids !== null) {
								data.department_ids = result[0].department_ids.join(',')
							} else {
								delete data.department_ids;
							}
						} else {
							delete data.department_ids;
						}
						changeHelp({
							data: {

								...data,
								is_all_seller: this.is_all_seller,
								seller_ids: this.is_all_seller == 2 ? this.seller_ids.map(it => {
									return it.sales_seller_id
								}).join(',') : '',
								is_all: this.is_all,
								id: this.temp_id ? this.temp_id : '',
							},
							info: true,
						}).then(res => {
							this.temp_id = ''
							this.visible = false;
							this.handleCancel()
							this.$refs.list.get_list();
						})
					} else {

						Promise.all([this.$refs.form_dap_data.submit()]).then(result => {
							if (!result[0].department_ids) {
								message.error("请选择公告公开部门")
								return false
							}
							if (this.temp_id) {
								delete data.department_ids;
							} else {
								data.department_ids = result[0].department_ids.join(',')
							}
							changeHelp({
								data: {
									...data,
									is_all_seller: this.is_all_seller,
									seller_ids: this.is_all_seller == 2 ? this.seller_ids.map(it => {
										return it.sales_seller_id
									}).join(',') : '',
									is_all: this.is_all,
									id: this.temp_id ? this.temp_id : '',
								},
								info: true,
							}).then(res => {
								this.temp_id = ''
								this.visible = false;
								this.handleCancel()
								this.$refs.list.get_list();
							})
						})
					}

				} else {
					console.log(444)
					if (!data.id) {
						data.id = '';
						if (data.department_ids !== null) {
							data.department_ids = data.department_ids.join(',')
						} else {
							delete data.department_ids;
						}
					} else {
						delete data.department_ids;
					}
					changeHelp({
						data: {
							...data,
							is_all_seller: this.is_all_seller,
							seller_ids: this.is_all_seller == 2 ? this.seller_ids.map(it => {
								return it.sales_seller_id
							}).join(',') : '',
							is_all: this.is_all,
							department_ids: '',
							id: this.temp_id ? this.temp_id : '',

						},
						info: true,
					}).then(res => {
						this.temp_id = ''
						this.visible = false;
						this.handleCancel()
						this.$refs.list.get_list();
					})


				}



			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.seller_ids_box {
		padding: 2px 10px;
		border: 1px solid #eee;
		border-radius: 4px;
	}

	.file_box {
		.file_li {
			position: relative;
			margin-top: 8px;
			padding: 8px;
			background: #fff;
			border: 1px dashed #e1e6eb;
			border-radius: 4px;
			cursor: pointer;
			transition: border-color 0.2s ease;
			display: flex;
			align-items: center;

			span {
				overflow: hidden;
				// text-overflow: ellipsis;
				// white-space: nowrap;
				// width: 210px;
				// margin-bottom: 4px;
				font-size: 14px;
				flex: 1;
				display: inline-block;
				margin-left: 10px;
				// line-height: 20px;
			}

			.anticon-delete {
				position: absolute;
				top: 50%;
				right: 16px;
				// margin-top: -8px;
				width: fit-content;
				color: rgb(187, 190, 196);
			}

			.anticon-delete:hover {
				color: #397dee;
			}
		}

		.file_li:hover {
			border-color: #397dee;
		}
	}

	iframe {
		border: none;
	}
</style>