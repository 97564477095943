<template>
    <div>
        <PopTableList
            :form_data_seo="form_data_seo_pop"
            :form_data="form_data_pop"
            :zIndex="2000"
			:where="where"
            :columns="pop_columns"
            :visible.sync="user_visible"
            :rowSelection="rowSelection"
            :get_table_list="get_table_list"
            @submit="submit"
            rowKey="seller_id"
        >
        <!-- :submit_preprocessing="submit_preprocessing" -->
            <!-- <template slot="department" slot-scope="data">
                <span>{{ data.record.department_join.department_join }}</span>
            </template> -->
            <!-- <template slot="user_status" slot-scope="data">
                <span v-if="data.text == -1">离职</span>
                <span v-else-if="data.text == 1">在职</span>
            </template> -->
            <div slot="pop_footer">
                已选择{{ selectedRowKeys.length || 0 }}人
            </div>
        </PopTableList>
    </div>
</template>

<script>
import PopTableList from './PopTableList'
import { getSellerSelect } from "@/api/personnel";
import props_pop from './props/pop'
import table_pop from './props/table'
const columns = [
	{
	    title: "SAP_id",
	    dataIndex: "sap_seller_id"
	},
    {
        title: "名称",
        dataIndex: "seller_name"
    },
    {
        title: "电话",
        dataIndex: "phone",
    },
    {
        title: "分类",
        dataIndex: "seller_type"
    },
    {
        title: "地址",
        dataIndex: "address",
    }
]

export default {
    name: 'SelectSeller',
    components: {
        PopTableList,
    },
    props: {
        ...props_pop,
        ...table_pop,
        select_type: {
            type: String,
            default: 'checkbox'
        },		
    },

    data() {
        return {
			where:{flag:1},
            user_visible: false,
            get_table_list: getSellerSelect,
            pop_columns: columns,
            // submit_preprocessing: {
            //     array_to_string: ['department_id']
            // },
            form_data_pop: {
                title: '请选经销商'
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        options: {}
                    }
                ]
            },
            select_user: {

            },
            select_user_list: [],
            selectedRowKeys: []
        }
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys;
					const map = new Map()
					this.select_user_list = [...this.select_user_list, ...selectedRows].filter(item => {
					    return !map.has(item.seller_id) && map.set(item.seller_id, item.seller_id) && this.selectedRowKeys.indexOf(item.seller_id) > -1
					});
                },
                selectedRowKeys: this.selectedRowKeys,
                type: this.select_type,
            };
        },
    },
    watch: {
        visible(new_value) {
            this.user_visible = new_value
        },
        user_visible(new_value) {
            this.$emit('update:visible', new_value)
        },
    },
    created() {
        // Promise.all([this.$method.get_department(), this.$method.get_role()]).then(res => {
        //     this.form_data_seo_pop.list.forEach(item => {
        //         if (item.name == "department_id") {
        //             item.treeData = res[0];
        //         }
        //         if (item.name == "role_id") {
        //             item.list = [...this.$config.status_all, ...res[1]];
        //         }
        //     });
        // })
    },
    methods: {
        submit() {
            this.$emit('submit', {
                data:this.select_user_list
            })
			
			this.selectedRowKeys = [];
        }
    }
}
</script>